<template>
  <div class="w-full">
    <div
      @click="onClickCta"
      v-if="labelType === 'free'"
      class="product-detail-primary-label"
      :class="customClass"
    >
      <img
        :src="require('@/assets/icon-label/free.webp')"
        alt="ฟรีของแถม"
        loading="lazy"
        class="image"
      />
      <span v-if="showMore" class="cta">ดูเพิ่มเติม</span>
    </div>
    <ModalLabelProduct
      v-if="showMore"
      ref="modal"
      :title="title"
      :data="data"
      :remark="remark"
    />
  </div>
</template>

<script>
import ModalLabelProduct from '@/components/bnn/ModalLabelProduct.vue'

export default {
  components: {
    ModalLabelProduct,
  },

  computed: {
    customClass() {
      if (this.showMore) {
        return '-can-see-detail'
      }
      return ''
    },
  },

  props: {
    title: {
      type: String,
      default: '',
    },

    labelType: {
      type: String,
      default: '',
    },

    showMore: {
      type: Boolean,
      default: false,
    },

    data: {
      type: Object,
      default: null,
    },

    remark: {
      type: String,
      default: '',
    },
  },

  methods: {
    onClickCta() {
      if (this.showMore) this.$refs.modal.open()
    },
  },
}
</script>

<style lang="stylus" scoped>
.product-detail-primary-label
  display: flex
  align-items: center
  width: fit-content
  margin-right .75rem
  margin-top .5rem
  border-radius .5rem
  font-size .875rem
  line-height 1.5

.product-detail-primary-label>.image
    width: 32px
    height 32px
    object-fit: contain

.product-detail-primary-label.-can-see-detail
   padding: .25rem 0 .25rem .25rem
   background-color $color-bnn-gray-light-2
   cursor pointer
.product-detail-primary-label.-can-see-detail>.cta
   font-weight: bold
   padding  0 .5rem

.w-full
    width: fit-content
</style>
