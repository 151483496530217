import { render, staticRenderFns } from "./ModalLabelProduct.vue?vue&type=template&id=46b4ba34&scoped=true"
import script from "./ModalLabelProduct.vue?vue&type=script&lang=js"
export * from "./ModalLabelProduct.vue?vue&type=script&lang=js"
import style0 from "./ModalLabelProduct.vue?vue&type=style&index=0&id=46b4ba34&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46b4ba34",
  null
  
)

export default component.exports