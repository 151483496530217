<template>
  <div
    @click="$emit('click')"
    :style="customStyle"
    :class="{
      select: select,
      disabled: disabled,
    }"
    class="btn-color"
  >
    <!-- <span v-if="!disabled" class="sold-out">
      Sold Out
    </span> -->
    <!-- <v-icon v-if="isShowCancelIcon" color="red">
      mdi-cancel
    </v-icon> -->
    <img
      v-if="isShowCancelIcon"
      src="@/assets/icon/icon-disabled-color.png"
    />
  </div>
</template>

<script>
export default {
  computed: {
    // customClass() {
    //   if (this.disabled) {
    //     return 'btn-color disabled'
    //   } else if (this.select) {
    //     return 'btn-color select'
    //   }
    //   return 'btn-color'
    // },
    customStyle() {
      return {
        backgroundColor:
          this.color.indexOf(`#`) > -1
            ? this.color
            : `#${this.color}`,
        height: `${this.height}px`,
        width: `${this.width}px`,
      }
    },
  },

  props: {
    color: {
      type: String,
      default: '',
    },
    height: {
      type: Number,
      default: 40,
    },
    width: {
      type: Number,
      default: 40,
    },
    select: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isShowCancelIcon: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="stylus" scoped>
.btn-color
  border-radius: 50px
  box-shadow: 0 0 2px 1.5px white;
  border: 3px solid white;
  display: flex
  align-items: center
  justify-content: center
  box-shadow: inset 0 2px 3px 1px rgba(0,0,0,0.1);
  transition: 0.2s ease-in-out;
  position relative
  //@media small
     //margin: 0 auto;
  @media (hover: hover) and (pointer: fine)
    &:hover:not(.disabled):not(.select)
      // border-color: transparent;
      // box-shadow: 0 0 0 1px $color-bnn-border;
      cursor: pointer
      transition: 0.2s ease-in-out;
  img
    width: 105%
    height: 105%
    position absolute
    top 50%
    left 50%
    transform: translate(-50%, -50%)
.btn-color.select
     // outline: 1.5px solid ;
     // outline-offset: 4px;
     // box-shadow: 0 0 2px 1.5px $color-bnn-border-2;
     box-shadow: 0 0 0 2px $color-border-btn-spec;
     border: 3px solid #FFF;

.btn-color.disabled:not(.select)
    box-shadow: none
    .sold-out
        color: red
        font-weight: bold
        font-size: 0.6rem
        text-shadow: 0 0 2px #fff;
</style>
