<template>
  <div
    class="accessory-bundle"
    :class="customClass"
    @click="isButton ? onChange() : false"
  >
    <div class="warp-title">
      <v-checkbox
        v-if="accessoryBundle.active && !isButton"
        :color="color['color-bnn']"
        @change="onChange()"
        v-model="checkAppleCare"
      ></v-checkbox>
      <div v-if="isButton" class="image">
        <img :src="image" />
      </div>
      <div class="title">{{ title }}</div>
      <div class="price" v-if="isDelivery">
        ฿{{ accessoryBundle.price_ship | currency }}.-
      </div>
      <div class="price" v-else-if="isStore">
        ฿{{ accessoryBundle.price_store | currency }}.-
      </div>
    </div>
    <template v-if="!isButton">
      <hr class="gray" />
      <div class="content">
        <div class="apple-care-image">
          <img :src="image" />
        </div>
        <div class="desc">
          <div>
            {{ description }}
          </div>
          <div class="price">
            <div
              v-if="isDelivery"
              class="text-xl font-semibold text-price"
            >
              ฿{{ accessoryBundle.price_ship | currency }}.-
            </div>
            <div
              v-else-if="isStore"
              class="text-xl font-semibold text-price"
            >
              ฿{{ accessoryBundle.price_store | currency }}.-
            </div>

            <div
              v-if="
                isDelivery &&
                  accessoryBundle.price_ship !==
                    accessoryBundle.price_srp
              "
              class="px-4 text-line-through"
            >
              ราคาปกติ ฿{{ priceSrp | currency }}.=
            </div>
            <div
              v-else-if="
                isStore &&
                  accessoryBundle.price_store !==
                    accessoryBundle.price_srp
              "
              class="px-4 text-line-through"
            >
              ราคาปกติ ฿{{ priceSrp | currency }}.=
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import color from '@/config/color'
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      cartDelivery: state => state.cart.delivery,
      selectSKU: state => state.model.selectSKU,
    }),

    customClass() {
      return {
        selected: this.isSelected,
        disabled: !this.accessoryBundle?.active,
      }
    },

    isDelivery() {
      return (
        this.cartDelivery === 'delivery' || this.cartDelivery === true
      )
    },

    isStore() {
      return this.cartDelivery === 'store'
    },

    title() {
      return this.accessoryBundle.name
    },

    image() {
      return this.accessoryBundle.image_url
    },

    priceSrp() {
      return this.accessoryBundle.price_srp
    },

    description() {
      return this.accessoryBundle?.description
    },

    isSelected() {
      const count = this.$store.getters['bundle/countBySKU'](
        this.accessoryBundle,
      )
      return count >= 1
    },
  },

  watch: {
    selectSKU() {
      this.checkAppleCare = false
    },
  },

  props: {
    accessoryBundle: {
      type: Object,
      required: true,
    },

    isButton: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      checkAppleCare: false,
      color: color,
    }
  },

  methods: {
    onChange() {
      this.$store.dispatch('bundle/toggle', this.accessoryBundle)
      this.$emit('change', this.accessoryBundle)
      this.$forceUpdate()
    },
  },
}
</script>

<style lang="stylus" scoped>

.accessory-bundle
    color: $color-bnn-gray-medium
    position relative
    // border: 1px solid $color-bnn-gray-light
    background white
    border-radius: 8px;
    display: flex
    justify-content: center
    flex-wrap: wrap
    flex-direction: column
    padding: 0 1rem
    margin-bottom: 1rem
    height 50px
    cursor pointer
    box-shadow: 0 0 0 1px $color-bnn-gray-light;
    transition: 0.2s ease-in-out;
    @media (hover: hover) and (pointer: fine)
      &:hover:not(.disabled)
        box-shadow: 0 0 0 2px $color-bnn-border;
        cursor: pointer
        transition: 0.2s ease-in-out;
    &:last-child
        margin-bottom: 0
    .warp-title
        width: 100%
        display: flex
        justify-content: flex-start
        flex-wrap: nowrap
        gap 1rem
        align-items: center
        // text-align: center
        // margin 1rem 0
        //justify-content: center
        // min-height: 60px
        .image
          width: 36px
          height: 36px
          aspect-ratio: 1/1
          display: flex
          img
            width: 100%
            height: 100%
            max-width: 36px
            max-height: 36px
            object-fit: contain
            -webkit-object-fit: contain
            object-position: center
            -webkit-object-position: center
        .title
          flex: auto
          font-size: 1rem !important
          font-family: $font-family-base !important
          line-height: 1
          font-weight: bold
        .price
          font-size: 1rem !important
          font-family: $font-family-base !important
          line-height: 1
          font-weight: bold
    .content
        display: flex
        flex: 1 1 auto
        padding: 1rem 0
        gap 1rem
        .apple-care-image
            width: 64px
            height: 64px
            object-fit: contain
            img
                width 100%
                min-width: 64px
        .desc
            color: $color-gray-2
            .price
                display: flex
                justify-content: flex-start
                position: relative
                align-items: center
                margin-top 1rem

.accessory-bundle.selected
    // border: 1px solid $color-bnn
    box-shadow: 0 0 0 1.5px $color-border-btn-spec;

.accessory-bundle.disabled
    opacity 0.5

.text-black
    color: $color-bnn-gray-medium

.text-price
    color: $color-text-price
</style>
