var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"accessory-bundle",class:_vm.customClass,on:{"click":function($event){_vm.isButton ? _vm.onChange() : false}}},[_c('div',{staticClass:"warp-title"},[(_vm.accessoryBundle.active && !_vm.isButton)?_c('v-checkbox',{attrs:{"color":_vm.color['color-bnn']},on:{"change":function($event){return _vm.onChange()}},model:{value:(_vm.checkAppleCare),callback:function ($$v) {_vm.checkAppleCare=$$v},expression:"checkAppleCare"}}):_vm._e(),(_vm.isButton)?_c('div',{staticClass:"image"},[_c('img',{attrs:{"src":_vm.image}})]):_vm._e(),_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.title))]),(_vm.isDelivery)?_c('div',{staticClass:"price"},[_vm._v(" ฿"+_vm._s(_vm._f("currency")(_vm.accessoryBundle.price_ship))+".- ")]):(_vm.isStore)?_c('div',{staticClass:"price"},[_vm._v(" ฿"+_vm._s(_vm._f("currency")(_vm.accessoryBundle.price_store))+".- ")]):_vm._e()],1),(!_vm.isButton)?[_c('hr',{staticClass:"gray"}),_c('div',{staticClass:"content"},[_c('div',{staticClass:"apple-care-image"},[_c('img',{attrs:{"src":_vm.image}})]),_c('div',{staticClass:"desc"},[_c('div',[_vm._v(" "+_vm._s(_vm.description)+" ")]),_c('div',{staticClass:"price"},[(_vm.isDelivery)?_c('div',{staticClass:"text-xl font-semibold text-price"},[_vm._v(" ฿"+_vm._s(_vm._f("currency")(_vm.accessoryBundle.price_ship))+".- ")]):(_vm.isStore)?_c('div',{staticClass:"text-xl font-semibold text-price"},[_vm._v(" ฿"+_vm._s(_vm._f("currency")(_vm.accessoryBundle.price_store))+".- ")]):_vm._e(),(
              _vm.isDelivery &&
                _vm.accessoryBundle.price_ship !==
                  _vm.accessoryBundle.price_srp
            )?_c('div',{staticClass:"px-4 text-line-through"},[_vm._v(" ราคาปกติ ฿"+_vm._s(_vm._f("currency")(_vm.priceSrp))+".= ")]):(
              _vm.isStore &&
                _vm.accessoryBundle.price_store !==
                  _vm.accessoryBundle.price_srp
            )?_c('div',{staticClass:"px-4 text-line-through"},[_vm._v(" ราคาปกติ ฿"+_vm._s(_vm._f("currency")(_vm.priceSrp))+".= ")]):_vm._e()])])])]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }