<template>
  <v-dialog v-model="dialog" persistent max-width="700">
    <v-card>
      <v-card-title>
        <div class="title-modal-layout">
          <h4>{{ title }}</h4>
          <div @click="close" class="close">
            <v-icon>mdi-close</v-icon>
          </div>
        </div>
      </v-card-title>
      <v-card-text>
        <div class="free-gifts">
          <div
            v-for="(item, index) of data"
            class="free-gift-item"
            :key="index"
          >
            <div class="item-desc">
              <div class="image-wrapper">
                <img :src="item.image" loading="lazy" class="image" />
              </div>
              <div class="inline-grid">
                <span class="name"> {{ item.name }}</span>
                <span class="limit">จำนวน: {{ item.limit }}</span>
              </div>
            </div>
            <div v-if="item.price === 0" class="item-price">
              ฿{{ item.price | currency }}.-
            </div>
            <div v-else class="item-price">
              ฟรี
            </div>
          </div>
        </div>

        <div v-if="remark" class="free-gifts-remark">
          {{ remark }}
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },

    remark: {
      type: String,
      default: '',
    },

    data: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      dialog: false,
    }
  },
  methods: {
    open() {
      this.dialog = true
    },

    close() {
      this.dialog = false
    },
  },
}
</script>

<style lang="stylus" scoped>

.title-modal-layout
    display: flex
    justify-content: space-between
    width: 100%
    .close
        cursor: pointer

.free-gifts
    background: $color-bnn-gray-light-2 0% 0% no-repeat padding-box;
    border-radius: 8px;
    display: flex
    justify-content: space-between
    flex: 1 1
    padding: 1rem
    margin-top: 1rem
    flex-direction: column
    @media screen and (max-width: 768px)
      padding: 1rem 0.5rem
      font-size: 0.85rem

.free-gift-item
    display: flex
    padding .5rem 0

.free-gift-item .item-desc
    display flex
    width 75%

.free-gift-item .item-desc .image-placeholder, .free-gift-item .item-desc .image-wrapper
    flex-shrink: 0

.free-gift-item .item-desc>.inline-grid
    margin-left 1rem
    display: inline-grid
    align-items center
    color: $color-secondary=-text

.free-gift-item .item-desc .image, .free-gift-item .item-desc .image-placeholder
    width: 3.5rem
    height: 3.5rem

.free-gift-item .item-price
    font-weight bold
    width: 25%
    line-height 1.5
    text-align right
    color $color-text-price
    font-size 1rem

.free-gift-item .item-desc>.inline-grid>.name
    font-size 1rem
    line-height 1.2

.free-gift-item .item-desc>.inline-grid>.limit
    font-size: .75rem
    color: rgba(0,0,0,.45)
    line-height 1.3

.free-gifts-remark
    color: rgba(0,0,0,.45)
    font-size: .75rem
    margin-bottom: 0
    margin-top: 0.75rem
    line-height: 1.3
</style>
