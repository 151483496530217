var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"btn-model",class:{
    select: _vm.select,
    disabled: _vm.disabled,
  },on:{"click":function($event){return _vm.$emit('click')}}},[_c('v-row',_vm._b({},'v-row',{
      justify: 'between',
      'no-gutters': true,
      align: 'center',
    },false),[_c('v-col',{staticClass:"title-medium"},[_vm._v(_vm._s(_vm.title))]),(_vm.isShowPrice && _vm.price)?_c('v-col',{staticClass:"text-right"},[_c('div',{staticClass:"label-l-regular"},[_vm._v("ราคาเริ่มต้น")]),_c('div',{staticClass:"title-medium"},[_vm._v("฿"+_vm._s(_vm._f("currency")(_vm.price)))])]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }