var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.isCard)?_c('div',[(_vm.isBnn)?_c('div',{staticClass:"apple-care-card",class:_vm.customClass,on:{"click":function($event){_vm.isButton ? _vm.onChange() : false}}},[_c('div',{staticClass:"title"},[(_vm.appleCare.active && !_vm.isButton)?_c('v-checkbox',{attrs:{"color":_vm.color['color-bnn']},on:{"change":function($event){return _vm.onChange()}},model:{value:(_vm.checkAppleCare),callback:function ($$v) {_vm.checkAppleCare=$$v},expression:"checkAppleCare"}}):_vm._e(),_vm._v(" "+_vm._s(_vm.title)+" "),(_vm.isDelivery)?_c('span',{staticClass:"ml-0"},[_vm._v(" ฿"+_vm._s(_vm._f("currency")(_vm.appleCare.price_ship))+".-")]):(_vm.isStore)?_c('span',{staticClass:"ml-0"},[_vm._v(" ฿"+_vm._s(_vm._f("currency")(_vm.appleCare.price_store))+".-")]):_vm._e()],1),(!_vm.isButton)?[_c('hr',{staticClass:"gray"}),_c('div',{staticClass:"content"},[_c('div',{staticClass:"apple-care-image"},[_c('img',{attrs:{"src":_vm.image}})]),_c('div',{staticClass:"desc"},[_c('div',[_vm._v(" "+_vm._s(_vm.description)+" ")]),_c('div',{staticClass:"price"},[(_vm.isDelivery)?_c('div',{staticClass:"text-xl font-semibold text-price"},[_vm._v(" ฿"+_vm._s(_vm._f("currency")(_vm.appleCare.price_ship))+".- ")]):(_vm.isStore)?_c('div',{staticClass:"text-xl font-semibold text-price"},[_vm._v(" ฿"+_vm._s(_vm._f("currency")(_vm.appleCare.price_store))+".- ")]):_vm._e(),(
                _vm.isDelivery &&
                  _vm.appleCare.price_ship !== _vm.appleCare.price_srp
              )?_c('div',{staticClass:"px-4 text-line-through"},[_vm._v(" ราคาปกติ ฿"+_vm._s(_vm._f("currency")(_vm.priceSrp))+".= ")]):(
                _vm.isStore &&
                  _vm.appleCare.price_store !== _vm.appleCare.price_srp
              )?_c('div',{staticClass:"px-4 text-line-through"},[_vm._v(" ราคาปกติ ฿"+_vm._s(_vm._f("currency")(_vm.priceSrp))+".= ")]):_vm._e()])])])]:_vm._e()],2):_vm._e()]):_c('div',[(_vm.isBnn)?_c('div',{staticClass:"apple-care-card-cart"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"apple-care-image"},[_c('img',{attrs:{"src":_vm.image}})]),_c('div',{staticClass:"desc"},[_c('div',[_vm._v(" "+_vm._s(_vm.description)+" ")]),_c('div',{staticClass:"price"},[(_vm.isDelivery)?_c('div',{staticClass:"text-md font-semibold"},[_vm._v(" ฿"+_vm._s(_vm._f("currency")(_vm.appleCare.price_ship))+".- ")]):(_vm.isStore)?_c('div',{staticClass:"text-md font-semibold"},[_vm._v(" ฿"+_vm._s(_vm._f("currency")(_vm.appleCare.price_store))+".- ")]):_vm._e(),(
              _vm.isDelivery &&
                _vm.appleCare.price_ship !== _vm.appleCare.price_srp
            )?_c('div',{staticClass:"px-4 text-line-through"},[_vm._v(" ราคาปกติ ฿"+_vm._s(_vm._f("currency")(_vm.priceSrp))+".= ")]):(
              _vm.isStore &&
                _vm.appleCare.price_store !== _vm.appleCare.price_srp
            )?_c('div',{staticClass:"px-4 text-line-through"},[_vm._v(" ราคาปกติ ฿"+_vm._s(_vm._f("currency")(_vm.priceSrp))+".= ")]):_vm._e()])]),_c('div',{staticClass:"btn-toggle"},[_c('v-btn',{class:[
            'btn-apple-care-toggle',
            { selected: _vm.isSelected },
          ],attrs:{"block":"","depressed":"","color":!_vm.isSelected ? '#ddd' : _vm.colorBnn['color-bnn'],"outlined":_vm.isSelected},on:{"click":function($event){return _vm.onChange()}}},[(!_vm.isSelected)?[_vm._v(" เพิ่ม ")]:[_c('v-icon',[_vm._v("mdi-check-circle")]),_vm._v(" เพิ่มแล้ว ")]],2)],1)])]):_c('ProductConfigurationItem',{attrs:{"product":_vm.appleCare,"label":_vm.appleCare.name},scopedSlots:_vm._u([{key:"slotProductTitle",fn:function(){return [_vm._v(" ปกป้องผลิตภัณฑ์ Apple ของคุณ ")]},proxy:true},{key:"slotProductPrice",fn:function(){return [(_vm.isDelivery)?_c('div',[_c('span',[_vm._v(" ฿"+_vm._s(_vm._f("currency")(_vm.appleCare.price_ship))+" ")]),(
            _vm.isDelivery &&
              _vm.appleCare.price_ship !== _vm.appleCare.price_srp
          )?_c('span',{staticClass:"srp-pricing text-line-through"},[_vm._v(" ฿"+_vm._s(_vm._f("currency")(_vm.priceSrp))+" ")]):_vm._e()]):(_vm.isStore)?_c('div',[_c('span',[_vm._v(" ฿"+_vm._s(_vm._f("currency")(_vm.appleCare.price_store))+" ")]),(
            _vm.isStore && _vm.appleCare.price_store !== _vm.appleCare.price_srp
          )?_c('span',{staticClass:"srp-pricing text-line-through"},[_vm._v(" ฿"+_vm._s(_vm._f("currency")(_vm.priceSrp))+" ")]):_vm._e()]):_vm._e()]},proxy:true},{key:"slotProductInfoAction",fn:function(){return [_c('v-btn',{staticClass:"btn-link",attrs:{"depressed":"","text":"","color":_vm.colorBnn['color-bnn']},on:{"click":_vm.openModalAppleCareInfo}},[_vm._v(" เรียนรู้เพิ่มเติม "),_c('v-icon',[_vm._v("mdi-chevron-right")])],1)]},proxy:true},{key:"slotProductAction",fn:function(){return [_c('v-btn',{class:[
          'btn-apple-care-toggle mt-md-0 mt-3',
          { selected: _vm.isSelected },
        ],attrs:{"block":"","depressed":"","color":_vm.colorBnn['color-bnn'],"outlined":""},on:{"click":function($event){return _vm.onChange()}}},[(!_vm.isSelected)?[_vm._v(" เพิ่ม ")]:[_vm._v(" เพิ่มแล้ว ")]],2)]},proxy:true}])}),_c('PDPModalAppleCareInfo',{ref:"PDPModalAppleCareInfo",attrs:{"htmlContent":_vm.appleCare.description}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }