var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isSlot)?_c('div',{staticClass:"btn-spec",class:{
    select: _vm.select,
    disabled: _vm.disabled,
  },on:{"click":function($event){return _vm.$emit('click')}}},[_vm._t("content")],2):_c('div',{staticClass:"btn-spec title-medium",class:{
    select: _vm.select,
    disabled: _vm.disabled,
  },on:{"click":function($event){return _vm.$emit('click')}}},[_c('span',[_vm._v(_vm._s(_vm.title))])])
}
var staticRenderFns = []

export { render, staticRenderFns }