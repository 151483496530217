<template>
  <div v-if="!isCard">
    <div
      v-if="isBnn"
      class="apple-care-card"
      @click="isButton ? onChange() : false"
      :class="customClass"
    >
      <div class="title">
        <v-checkbox
          v-if="appleCare.active && !isButton"
          :color="color['color-bnn']"
          @change="onChange()"
          v-model="checkAppleCare"
        ></v-checkbox>
        {{ title }}
        <span v-if="isDelivery" class="ml-0">
          ฿{{ appleCare.price_ship | currency }}.-</span
        >
        <span v-else-if="isStore" class="ml-0">
          ฿{{ appleCare.price_store | currency }}.-</span
        >
      </div>
      <template v-if="!isButton">
        <hr class="gray" />
        <div class="content">
          <div class="apple-care-image">
            <img :src="image" />
          </div>
          <div class="desc">
            <div>
              {{ description }}
            </div>
            <div class="price">
              <div
                v-if="isDelivery"
                class="text-xl font-semibold text-price"
              >
                ฿{{ appleCare.price_ship | currency }}.-
              </div>
              <div
                v-else-if="isStore"
                class="text-xl font-semibold text-price"
              >
                ฿{{ appleCare.price_store | currency }}.-
              </div>

              <div
                v-if="
                  isDelivery &&
                    appleCare.price_ship !== appleCare.price_srp
                "
                class="px-4 text-line-through"
              >
                ราคาปกติ ฿{{ priceSrp | currency }}.=
              </div>
              <div
                v-else-if="
                  isStore &&
                    appleCare.price_store !== appleCare.price_srp
                "
                class="px-4 text-line-through"
              >
                ราคาปกติ ฿{{ priceSrp | currency }}.=
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
  <div v-else>
    <div v-if="isBnn" class="apple-care-card-cart">
      <div class="content">
        <div class="apple-care-image">
          <img :src="image" />
        </div>
        <div class="desc">
          <div>
            {{ description }}
          </div>
          <div class="price">
            <div v-if="isDelivery" class="text-md font-semibold">
              ฿{{ appleCare.price_ship | currency }}.-
            </div>
            <div v-else-if="isStore" class="text-md font-semibold">
              ฿{{ appleCare.price_store | currency }}.-
            </div>

            <div
              v-if="
                isDelivery &&
                  appleCare.price_ship !== appleCare.price_srp
              "
              class="px-4 text-line-through"
            >
              ราคาปกติ ฿{{ priceSrp | currency }}.=
            </div>
            <div
              v-else-if="
                isStore &&
                  appleCare.price_store !== appleCare.price_srp
              "
              class="px-4 text-line-through"
            >
              ราคาปกติ ฿{{ priceSrp | currency }}.=
            </div>
          </div>
        </div>
        <div class="btn-toggle">
          <v-btn
            block
            depressed
            :class="[
              'btn-apple-care-toggle',
              { selected: isSelected },
            ]"
            :color="!isSelected ? '#ddd' : colorBnn['color-bnn']"
            :outlined="isSelected"
            @click="onChange()"
          >
            <template v-if="!isSelected">
              เพิ่ม
            </template>
            <template v-else>
              <v-icon>mdi-check-circle</v-icon>
              เพิ่มแล้ว
            </template>
          </v-btn>
        </div>
      </div>
    </div>
    <ProductConfigurationItem
      v-else
      :product="appleCare"
      :label="appleCare.name"
    >
      <template v-slot:slotProductTitle>
        ปกป้องผลิตภัณฑ์ Apple ของคุณ
      </template>

      <template v-slot:slotProductPrice>
        <div v-if="isDelivery">
          <span> ฿{{ appleCare.price_ship | currency }} </span>
          <span
            v-if="
              isDelivery &&
                appleCare.price_ship !== appleCare.price_srp
            "
            class="srp-pricing text-line-through"
          >
            ฿{{ priceSrp | currency }}
          </span>
        </div>
        <div v-else-if="isStore">
          <span> ฿{{ appleCare.price_store | currency }} </span>
          <span
            v-if="
              isStore && appleCare.price_store !== appleCare.price_srp
            "
            class="srp-pricing text-line-through"
          >
            ฿{{ priceSrp | currency }}
          </span>
        </div>
      </template>

      <template v-slot:slotProductInfoAction>
        <v-btn
          depressed
          text
          class="btn-link"
          :color="colorBnn['color-bnn']"
          @click="openModalAppleCareInfo"
        >
          เรียนรู้เพิ่มเติม <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </template>

      <template v-slot:slotProductAction>
        <v-btn
          block
          depressed
          :class="[
            'btn-apple-care-toggle mt-md-0 mt-3',
            { selected: isSelected },
          ]"
          :color="colorBnn['color-bnn']"
          outlined
          @click="onChange()"
        >
          <template v-if="!isSelected">
            เพิ่ม
          </template>
          <template v-else>
            เพิ่มแล้ว
          </template>
        </v-btn>
      </template>
    </ProductConfigurationItem>

    <PDPModalAppleCareInfo
      ref="PDPModalAppleCareInfo"
      :htmlContent="appleCare.description"
    />
  </div>
</template>

<script>
import color from '@/config/color'
import { mapState, mapGetters } from 'vuex'
import colorBnn from '@/config/color'
import isBnn from '@/config/isBnn'

export default {
  components: {
    ProductConfigurationItem: () =>
      import('@/components/studio7/ProductConfigurationItem.vue'),

    PDPModalAppleCareInfo: () =>
      import('@/components/studio7/PDPModalAppleCareInfo.vue'),
  },
  computed: {
    ...mapState({
      cartDelivery: state => state.cart.delivery,
      selectSKU: state => state.model.selectSKU,
    }),

    ...mapGetters({
      appleCare: 'model/getAppleCare',
    }),

    customClass() {
      return {
        selected: this.isSelected,
        disabled: !this.appleCare?.active,
      }
    },

    isDelivery() {
      return (
        this.cartDelivery === 'delivery' || this.cartDelivery === true
      )
    },

    isStore() {
      return this.cartDelivery === 'store'
    },

    title() {
      return this.appleCare.name
    },

    image() {
      return this.appleCare.image_url
    },

    priceSrp() {
      return this.appleCare.price_srp
    },

    description() {
      return this.appleCare?.description
    },

    isSelected() {
      const count = this.$store.getters['bundle/countBySKU'](
        this.appleCare,
      )
      return count >= 1
    },
  },

  watch: {
    selectSKU() {
      this.checkAppleCare = false
    },
  },

  props: {
    isButton: {
      type: Boolean,
      default: false,
    },
    isCard: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      colorBnn,
      isBnn,
      checkAppleCare: false,
      color: color,
    }
  },

  methods: {
    onChange() {
      this.$store.dispatch('bundle/toggle', this.appleCare)
      this.$emit('change', this.appleCare)
      this.$forceUpdate()
    },
    openModalAppleCareInfo() {
      this.$refs.PDPModalAppleCareInfo.openModal()
    },
  },
}
</script>

<style lang="stylus" scoped>

.apple-care-card
    color: $color-bnn-gray-medium
    position relative
    border: 1px solid $color-bnn-gray-light
    background white
    border-radius: 8px;
    display: flex
    justify-content: center
    align-items: center
    flex-wrap: wrap
    flex-direction: column
    padding: 0 1rem
    margin: 0.5rem 0
    height 50px
    cursor pointer
    .title
        width: 100%
        text-align: center
        font-size: 1rem !important
        font-weight: bold
        // margin 1rem 0
        display: flex
        justify-content: center
        gap 1rem
        align-items: center
        min-height: 60px
        font-family: $font-family-base !important
    .content
        display: flex
        flex: 1 1 auto
        padding: 1rem 0
        gap 1rem
        .apple-care-image
            width: 64px
            height: 64px
            object-fit: contain
            img
                width 100%
                min-width: 64px
        .desc
            color: $color-gray-2
            .price
                display: flex
                justify-content: flex-start
                position: relative
                align-items: center
                margin-top 1rem

.apple-care-card.selected
    border: 1px solid $color-bnn

.apple-care-card.disabled
    opacity 0.5

.text-black
    color: $color-bnn-gray-medium

.text-price
    color: $color-text-price

.apple-care-card-cart
	.content
		display: flex
		flex-wrap: wrap
		flex: 1 1 auto
		padding: 12px
		gap 1rem
		background-color: #f7f7f7
		border-radius: 8px
		.apple-care-image
			width: 48px
			height: 48px
			object-fit: contain
			img
				width 100%
				min-width: 48px
		.desc
			flex: 1
			font-size: 14px
			.price
				display: flex
				justify-content: flex-start
				position: relative
				align-items: center
				margin-top: 4px
		.btn-toggle
			width: 100%
			flex: auto
			@media min-medium
				min-width: 105px
				width: fit-content
				flex: 0
			.btn-apple-care-toggle
				min-width: 105px
				font-weight: bold
				color: #2d2d2d
				.v-icon
					font-size: 14px
					margin-right: 4px
				&.selected
					color: white
		.text-white
			color: white

.srp-pricing
  color: rgba(0, 0, 0, 0.45)
  margin-left: 8px
</style>
