<template>
  <div class="w-full">
    <v-tabs
      :bg-color="colorBnn['color-bnn']"
      :color="colorBnn['color-bnn']"
      class="px-4"
    >
      <v-tab
        v-if="tabContent"
        value="content"
        @click="tab = 'content'"
        >Content</v-tab
      >
      <v-tab
        v-if="tabCompare"
        value="compare"
        @click="tab = 'compare'"
        >Compare</v-tab
      >
    </v-tabs>

    <v-card-text>
      <v-window v-model="tab">
        <v-window-item value="content">
          <slot name="content"></slot>
        </v-window-item>

        <v-window-item value="compare">
          <slot name="compare"></slot>
        </v-window-item>
      </v-window>
    </v-card-text>
  </div>
</template>

<script>
import colorBnn from '@/config/color'

export default {
  props: {
    tab: {
      type: String,
      default: 'content',
    },
    tabContent: {
      type: Boolean,
      default: false,
    },
    tabCompare: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      colorBnn,
    }
  },
}
</script>
